export const faultLiftTypeList = [
  {
    value: 0,
    label: "自动解除",
  },
  {
    value: 1,
    label: "手动解除",
  },
];

export const faultStateList = [
  {
    value: 0,
    label: "已解除",
  },
  {
    value: 1,
    label: "故障中",
  },
];
