<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="main">
      <el-descriptions title="用户信息" :column="1" size="medium">
        <el-descriptions-item label="所属商户">
          {{ faultDetail.companyName || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="设备编号">
          <el-link type="primary" @click="onClickDeviceCode">{{ faultDetail.deviceCode }}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="设备类型">
          {{ faultDetail.deviceTypeLabel }}
        </el-descriptions-item>
        <el-descriptions-item label="插座号">{{ faultDetail.slotNum || "-" }}</el-descriptions-item>
        <el-descriptions-item label="站点名称">
          {{ faultDetail.stationName || "-" }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="a-mt-16" title="故障信息" :column="1" size="medium">
        <el-descriptions-item label="故障内容">{{ faultDetail.faultContent }}</el-descriptions-item>
        <el-descriptions-item label="故障状态">
          {{ faultDetail.faultStateLabel }}
        </el-descriptions-item>
        <el-descriptions-item label="故障开始时间">
          {{ faultDetail.faultStartTime }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        v-if="faultDetail.orderId"
        class="a-mt-16"
        title="用户信息"
        :column="1"
        size="medium"
      >
        <el-descriptions-item label="订单编号">
          <el-link type="primary" @click.prevent="onClickOrderId">
            {{ faultDetail.orderId }}
          </el-link>
        </el-descriptions-item>
        <el-descriptions-item label="用户昵称">{{ faultDetail.userName }}</el-descriptions-item>
        <el-descriptions-item label="手机号码">{{ faultDetail.phone }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        v-if="faultDetail.faultEndTime"
        class="a-mt-16"
        title="解除信息"
        :column="1"
        size="medium"
      >
        <el-descriptions-item label="解除类型">
          {{ faultDetail.faultLiftTypeLabel }}
        </el-descriptions-item>
        <el-descriptions-item label="操作人">
          {{ faultDetail.operationUserName }}
        </el-descriptions-item>
        <el-descriptions-item label="故障结束时间">
          {{ faultDetail.faultEndTime }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="_footer">
        <el-button v-if="!faultDetail.faultEndTime" type="primary" @click="onClickClear">
          解除
        </el-button>
        <el-button @click="onClickBack">返回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { faultLiftTypeList, faultStateList } from "./const";

export default {
  name: "FaultDetail",

  data() {
    this.id = 0;
    return {
      faultDetail: {},
      deviceTypeList: [],
    };
  },

  async created() {
    this.id = this.$route.query.id;
    await this.getDeviceType();
    this.getFaultDetail();
  },

  methods: {
    ...mapActions("todonum", ["getTodoNum"]),

    getFaultDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getDeviceFaultDetail,
          query: {
            deviceFaultId: this.id,
          },
        })
        .then(({ data }) => {
          this.faultDetail = {
            ...data,
            slotNum: data.slotNum || "-",
            deviceTypeLabel:
              this.deviceTypeList.find((item) => item.id === data.deviceType)?.name || "-",
            faultStateLabel:
              faultStateList.find((item) => item.value === data.faultState)?.label || "-",
            faultLiftTypeLabel:
              faultLiftTypeList.find((item) => item.value === data.faultLiftType)?.label || "-",
          };
        });
    },

    async getDeviceType() {
      const res = await this.$Axios._post({
        url: this.$Config.apiUrl.getDeviceType,
        params: {
          pageNum: 1,
          pageSize: 100000,
        },
      });
      this.deviceTypeList = res.data.list;
    },

    onClickDeviceCode() {
      this.$router.push({
        path: "/device/device-info",
        query: {
          deviceCode: this.faultDetail.deviceCode,
        },
      });
    },

    onClickOrderId() {
      this.$router.push({
        path: "/order/order-detail",
        query: {
          orderId: this.faultDetail.orderId,
        },
      });
    },

    onClickClear() {
      this.$confirm("请确认是否解除该设备的故障？", "解除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.$Axios
          ._put({
            url: this.$Config.apiUrl.manualClearFault,
            query: {
              deviceFaultId: this.id,
            },
          })
          .then(() => {
            this.$message.success("故障解除成功");
            this.getTodoNum();
            this.getFaultDetail();
          });
      });
    },

    onClickBack() {
      this.$router.push("/fault/fault-list");
      this.$store.dispatch("delView", this.$route);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  position: relative;
  height: 100%;
  overflow: auto;
}

._footer {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
</style>
